<template>
  <div>
    <l-button type="primary" class="c-top_right_btn" @click="editRow()">
      新建用户
    </l-button>

    <l-search @search="search(1)" @reset="resetForm">
      <l-form layout="inline" laba-width="70px">
        <l-form-item label="集团">
          <l-select v-model="searchFilter.companyId" placeholder="请选择集团">
            <l-select-option label="全部" :value="-2">全部</l-select-option>
            <l-select-option label="运营支撑系统" :value="-1">运营支撑系统</l-select-option>
            <l-select-option v-for="item in companyList" :key="item.id" :value="item.id">{{ item.name }}</l-select-option>
          </l-select>
        </l-form-item>
      </l-form>
    </l-search>

    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column
        data-index="id"
        title="流水号"
      />
      <l-table-column
        data-index="orgAccount"
        title="原始账号"
      />
      <l-table-column
        data-index="account"
        title="登录账号"
      />
      <l-table-column
        data-index="email"
        title="邮箱"
      />
      <l-table-column
        data-index="name"
        title="名字"
      />
      <l-table-column
        title="创建时间"
      >
        <template slot-scope="scope">{{ scope.createTime | date }}</template>
      </l-table-column>
      <l-table-column
        data-index="createName"
        title="创建人"
      />
      <l-table-column title="更多">
        <template v-if="scope.isAdmin !== 1" slot-scope="scope">
          <l-button
            title="编辑"
            type="link"
            @click="editRow(scope)"
          >
            编辑
          </l-button>
          <l-button
            v-if="scope.isActive === 1"
            title="冻结"
            type="link"
            @click="activeRow(scope,0)"
          >
            冻结
          </l-button>
          <l-button
            v-if="scope.isActive === 0"
            title="激活"
            type="link"
            @click="activeRow(scope,1)"
          >
            激活
          </l-button>
          <l-button type="link" @click="$toPage('/auth-app-user-instance/list',{userId: scope.id})">
            用户实例
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->

    <!-- 保存记录-STR -->
    <l-modal
      v-model="editDialog.show"
      :title="editDialog.data.id ? '修改用户' : '添加用户'"
      width="600px"
      cancel-text="取消"
      ok-text="确认"
      @ok="saveDialog"
    >
      <l-form-model
        ref="form"
        :model="editDialog.data"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <l-form-model-item label="用户姓名" prop="name" required>
          <l-input
            v-model="editDialog.data.name"
            placeholder="请输入用户姓名"
          />
        </l-form-model-item>
        <l-form-model-item label="用户邮箱" prop="email" required>
          <l-input
            v-model="editDialog.data.email"
            placeholder="请输入用户邮箱"
          />
        </l-form-model-item>
        <l-form-model-item label="用户账号" prop="orgAccount" required>
          <l-input
            v-model="editDialog.data.orgAccount"
            placeholder="请输入用户账号"
          />
        </l-form-model-item>
        <l-form-model-item label="用户手机" prop="phone" required>
          <l-input
            v-model="editDialog.data.phone"
            placeholder="请输入用户手机"
          />
        </l-form-model-item>
        <l-form-model-item
          v-if="!editDialog.data.id"
          label="初始密码"
          prop="passwd"
          required
        >
          <l-input
            v-model="editDialog.data.passwd"
            placeholder="请输入初始密码"
          />
        </l-form-model-item>
        <l-form-model-item
          v-if="!editDialog.data.id"
          label="确认密码"
          prop="repasswd"
          required
        >
          <l-input
            v-model="editDialog.data.repasswd"
            placeholder="请输入确认密码"
          />
        </l-form-model-item>
      </l-form-model>
    </l-modal>
    <!-- 保存记录-END -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthUserList',
  data() {
    return {
      searchFilter: {
        companyId: -1
      },
      appList: [],
      loading: false,
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 10,
        list: []
      },
      editDialog: {
        data: {
          id: '',
          orgAccount: '',
          name: '',
          email: '',
          phone: '',
          passwd: '',
          repasswd: ''
        },
        save: false,
        show: false
      },
      rules: {}
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.auth.companyList
    })
  },
  mounted() {
    this.search(1)
    this.$store.dispatch('auth/authCompany')
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page
      const param = {
        page: page || 1,
        size: this.tableData.size
      }

      for (const key in this.searchFilter) {
        param[key] = this.searchFilter[key]
      }

      this.tableData.list = []
      this.tableData.isLoading = true

      try {
        const data = await this.$store.dispatch('auth/authUser', param)
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page || 1
      } catch (e) {
        this.tableData.isLoading = false
      }
    },
    resetForm() {
      this.searchFilter = this.$options.data().searchFilter
      this.search(1)
    },
    editRow(row) {
      row = row || {}
      this.editDialog.data = JSON.parse(JSON.stringify(row))
      for (const key in this.editDialog.errmsg) {
        this.editDialog.errmsg[key] = ''
      }
      this.remoteMethod()
      this.editDialog.show = true
    },
    async activeRow(row, active) {
      row = row || {}
      const data = JSON.parse(JSON.stringify(row))
      data.isActive = active
      await this.$store.dispatch('auth/authUserActive', data)
      row.isActive = active
    },
    async remoteMethod(query) {
      this.loading = true
      const data = await this.$store.dispatch('auth/authAppList', {
        quickSearch: query,
        type: 1
      })
      this.appList = data.list
      this.loading = false
    },
    async saveDialog() {
      if (!this.editDialog.data.id) {
        if (!this.editDialog.data.passwd) {
          this.editDialog.errmsg.passwd = '请输入密码'
          return
        }
        if (this.editDialog.data.passwd !== this.editDialog.data.repasswd) {
          this.editDialog.errmsg.repasswd = '请确认二次密码一致'
          return
        }
      }
      this.editDialog.save = true
      await this.$store.dispatch('auth/authUserSave', this.editDialog.data)
      this.editDialog.show = false
      this.search()
    }
  }
}
</script>

<style lang="scss">
.errmsg,
.require {
  color: $l-danger;
}
.system-index-btns {
  text-align: right;
  & i {
    font-size: 12px;
    margin-right: 8px;
    vertical-align: top;
  }
  & span {
    display: inline-block;
    vertical-align: top;
  }
}
.device-info {
  .device-info-body {
    margin: 15px 15px 70px;
  }
  .l-dialog__header {
    border-bottom: 1px solid $l-border-base;
  }
  .info-line {
    margin-bottom: 10px;
    span {
      display: inline-block;
      padding: 5px 0;
      color: $l-text-primary;
      font-size: 14px;
      line-height: 24px;
    }
    span.name {
      min-width: 110px;
      line-height: 24px;
    }
  }
}
</style>
